import Home from "../Pages/Home";
const Main = () => {
  return (
    <>
      <div className="p-10">
        <Home />
      </div>
    </>
  );
};

export default Main;
