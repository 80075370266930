import Logo from "./Logo";
import { useState } from "react";

const Card = (props) => {
  const [seeProject, setSeeProject] = useState(false);
  const handleMouseEnter = () => {
    setSeeProject(true);
  };
  
  const handleMouseLeave = () => {
    setSeeProject(false);
  };
  return (
    <>
      <div className="" >
        <div class="relative flex w-full max-w-[20rem] flex-col rounded-xl opacity-100 text-web_portfolio_text bg-clip-border shadow-lg">
          <div class="relative mx-4 mt-4 overflow-hidden text-white rounded-xl bg-blue-gray-500 bg-clip-border shadow-blue-gray-500/40">
            <img
              className=" object-scale-down w-[22rem] h-[250px]"
              src={require(`/src/Images/${props.image}`)}
              alt="ui/ux review check"
            />
            <div class="absolute inset-0 w-full h-full to-bg-black-10 bg-gradient-to-tr from-transparent via-transparent "></div>
          </div>
          <div class="p-6">
            <div class="flex items-center justify-between mb-3">
              <h5 class="block opacity-100 font-sans text-xl antialiased font-medium leading-snug tracking-normal text-blue-gray-900">
                {props.title}
              </h5>
            </div>
            <p class="block font-sans h-full min-h-[6rem] text-base antialiased font-light leading-relaxed text-web_portfolio_text">
              {props.description}
            </p>
            <div class="inline-flex flex-wrap items-center gap-3 group">
              {props.stack.map((data, idx) => (
                <Logo image={data.imageName} tooltip={data.tooltipName} />
              ))}
            </div>
          </div>
          <div class="pb-6 mt-0 text-center flex justify-center " onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <a href={props.hyperlink} target="_blank">
              <button
                class=" flex justify-center select-none rounded-lg bg-gray-500  py-3.5 px-7 text-center align-middle font-sans text-sm font-bold uppercase text-white shadow-md shadow-gray-900/10 transition-all  focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                type="button"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <g fill="none">
                    <g clip-path="url(#akarIconsGithubFill0)">
                      <path
                        fill="currentColor"
                        fill-rule="evenodd"
                        d="M12 0C5.37 0 0 5.37 0 12c0 5.31 3.435 9.795 8.205 11.385c.6.105.825-.255.825-.57c0-.285-.015-1.23-.015-2.235c-3.015.555-3.795-.735-4.035-1.41c-.135-.345-.72-1.41-1.23-1.695c-.42-.225-1.02-.78-.015-.795c.945-.015 1.62.87 1.845 1.23c1.08 1.815 2.805 1.305 3.495.99c.105-.78.42-1.305.765-1.605c-2.67-.3-5.46-1.335-5.46-5.925c0-1.305.465-2.385 1.23-3.225c-.12-.3-.54-1.53.12-3.18c0 0 1.005-.315 3.3 1.23c.96-.27 1.98-.405 3-.405s2.04.135 3 .405c2.295-1.56 3.3-1.23 3.3-1.23c.66 1.65.24 2.88.12 3.18c.765.84 1.23 1.905 1.23 3.225c0 4.605-2.805 5.625-5.475 5.925c.435.375.81 1.095.81 2.22c0 1.605-.015 2.895-.015 3.3c0 .315.225.69.825.57A12.02 12.02 0 0 0 24 12c0-6.63-5.37-12-12-12"
                        clip-rule="evenodd"
                      />
                    </g>
                    <defs>
                      <clipPath id="akarIconsGithubFill0">
                        <path fill="#fff" d="M0 0h24v24H0z" />
                      </clipPath>
                    </defs>
                  </g>
                </svg>
                <span className="pl-3 pt-[2px]">{!seeProject ? "Visit Github" : "Click Me Please 😭" } </span>
              </button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
