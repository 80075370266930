import { useState } from 'react';
import { Tooltip } from 'react-tooltip';

const Logo = (props) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const handleMouseEnter = () => {
    setTooltipVisible(true);
  };

  const handleMouseLeave = () => {
    setTooltipVisible(false);
  };

  return (
    <span
      className="cursor-pointer rounded-full border border-gray-900/5 bg-web_portfolio_text shadow-white p-3 text-gray-900 transition-colors hover:!opacity-100 group-hover:opacity-70"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <img src={`${props.image}`} className="w-6 tooltips" alt="" />
      {tooltipVisible && (
        <Tooltip anchorSelect=".tooltips" place="top">
          {props.tooltip}
          {/* Klo lagi gabut nambahin hyperlink ke masing masing framework aja */}
        </Tooltip>
      )}
    </span>
  );
};

export default Logo;
