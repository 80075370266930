const dataSet = [
    {contactLogo : "https://api.iconify.design/basil:gmail-outline.svg", hyperlink:"mailto:vincent7564756@gmail.com"},
    {contactLogo : "https://api.iconify.design/akar-icons:github-fill.svg", hyperlink:"https://github.com/Vincent7564"},
    {contactLogo : "https://api.iconify.design/mdi:instagram.svg", hyperlink:"https://www.instagram.com/vincent756"},
]

const Contact = () =>{
    return(
        <>
        <div className=" text-center">
            <h1 className="kanit-medium text-5xl text-center pb-8 text-slate-300">
                Contact
            </h1>
            <div className=" flex justify-center">
            {dataSet.map((data)=>(
                <a href={`${data.hyperlink}`} className="pr-6" target="_blank">
                    <img src={`${data.contactLogo}`} className="w-16 h-16 rounded-full border-white border-2 bg-white" alt="asd" />
                </a>
            ))}
            </div>
        </div>
    </>
    )
}

export default Contact;