
const Footer = () => {
    return (
      <>
          <footer className="fixed w-screen z-50 bottom-0 bg-zinc-900 text-gray-300 text-center font-semibold">
                Created By Vin Cent @2024
          </footer>
      </>
    );
  };
  
  export default Footer;
  