import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  'paddingBottom' : '10px',
  'backgroundColor' : '#0e0f16',
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : '#9fa4d0',
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  backgroundColor : '#9fa4d0'
}));

export default function CustomizedAccordions({ idx, expandedIndex, onAccordionChange, header, description, year,title }) {
  const handleChange = () => {
    onAccordionChange(idx);
  };

  return (
    <div className="">
      <Accordion className="" expanded={idx === expandedIndex} onChange={handleChange}>
        <AccordionSummary
          aria-controls={`panel${idx}d-content`}
          id={`panel${idx}d-header`}
          expandIcon={idx === expandedIndex ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        >
          <div className="w-[100%] flex justify-between">
            <Typography variant='h6'>{title} @ {header}</Typography>
            <Typography variant='h6'>{year}</Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {description.map((data, idx) => (
              <p className="font-sans font-normal leading-relaxed" key={idx}>{data}</p>
            ))}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
