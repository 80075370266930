import React, { useState,useEffect } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import gsap from 'gsap';
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Header() {
  const [navigation, setNavigation] = useState([
    { name: 'Dashboard', href: '#home', current: false , offset : "100" },
    { name: 'Projects', href: '#project', current: false, offset:"5" },
    { name: 'Working Experience', href: '#working', current: false },
    { name: 'Contact', href: '#contact', current: false },    
  ]);

  useEffect(() => {
    gsap.from('.header-item', {
      opacity: 0,
      y: -20,
      duration: 0.5,
      stagger: 0.5,
      ease: "power1.out",
    });
    gsap.to('.header-item',{
      opacity:1,
      y: -20,
      duration: 0.5,
      stagger: 0.5,
      ease: "power1.in",
    })
  }, []);

  const handleLinkClick = (index) => {
    const updatedNavLinks = navigation.map((link, i) => {
      return i === index ? { ...link, current: true } : { ...link, current: false };
    });
    setNavigation(updatedNavLinks);
  };

  return (
    <nav className="flex items-center justify-between flex-wrap bg-web_portfolio_background p-5 sticky top-0 z-[1030]">
      <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
        <div className="flex items-center flex-shrink-0 text-white mr-6 pl-10">
        <span className="font-semibold text-xl tracking-tight">
            <AnchorLink href='#home' offset="100" className="header-item">
            Vin Cent
            </AnchorLink>
            </span>
          </div>
        <div className="text-sm lg:flex-grow">
          {navigation.map((item, index) => (
            <AnchorLink
              key={item.name}
              href={item.href} 
              className={classNames(
                item.current
                  ? 'bg-gray-900 text-white'
                  : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                'px-3 py-2 rounded-md text-sm font-medium header-item',
              )}
              offset={item.offset} 
              onClick={() => handleLinkClick(index)}
            >
              {item.name}
            </AnchorLink>
          ))}
        </div>
      </div>
    </nav>
  );
}
