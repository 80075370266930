import { BrowserRouter, Routes, Route } from "react-router-dom";
import RouteList from "./Route/RouteList";
import Main from "./Layout/Main";
import Footer from "./Layout/Component/Footer"
import Header from "./Layout/Component/Header";
import './App.css';
import './index.css';
import AnchorLink from "react-anchor-link-smooth-scroll";
import Project from "./Pages/Project";
import WorkingExperiences from "./Pages/WorkingExperiences";
import Contact from "./Pages/Contact";
import { useEffect } from "react";
import gsap from "gsap";
function App() {
  const reveal = () =>{
    const reveals = document.querySelectorAll(".reveal");

    for (let i = 0; i < reveals.length; i++) {
      let windowHeight = window.innerHeight;
      let elementTop = reveals[i].getBoundingClientRect().top;
      let elementVisible = 150;
  
      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      }
    }
  }
  window.addEventListener("scroll", reveal);
  return (
    <>
    <Header/>
    <AnchorLink href='#home' />
    <AnchorLink href='#project'/>
    <AnchorLink href='#working'/>
    <AnchorLink href='#contact'/>
      <BrowserRouter>
      <div className="flex justify-center bg-web_portfolio_background text-web_portfolio_text">
        <div className="w-[75%]">
          <section id="home" className="fade-in" >
            <Main />
          </section>
          <section id="project" className="pt-24 pb-24 reveal fade-bottom">
            <Project />
          </section>
          <section id="working" className="pt-24 pb-20 reveal fade-bottom">
            <WorkingExperiences />
          </section>
          <section id="contact" className="pb-24 pt-20 reveal fade-bottom">
            <Contact />
          </section>
        </div>
        </div>  
        <RouteList />
      </BrowserRouter>
    <Footer/>
    </>
  );
}

export default App;
