import { BrowserRouter, Routes, Route } from "react-router-dom";
import Main from "../Layout/Main"
import Project from "../Pages/Project"
import Contact from "../Pages/Contact";
const RouteList = () => {
    
    return (
        <Routes>
          <Route path="#home" element={<Main />} />
          <Route path="#project" element={<Project />} />
          <Route path="#contact" element={<Contact />} />
        </Routes>
    );
  };

export default RouteList;
